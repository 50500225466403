/*
	Helpers
*/
function get_html_object(el) {
	if (el instanceof HTMLElement || el instanceof Document) {
		return el;
	} else if (typeof el === "string") {
		return document.querySelector(el);
	} else {
		return false;
	}
}

export function add_class_if_not_exists(el, className) {
	let element = get_html_object(el);

	if (!element) {
		return false;
	}

	if (!element.classList.contains(className)) {
		return element.classList.add(className);
	}
}

export function remove_class_if_exists(el, className) {
	let element = get_html_object(el);

	if (!element) {
		return false;
	}

	if (element.classList.contains(className)) {
		return element.classList.remove(className);
	}
}

export function replace_class(el, old_classname, new_classname) {
	let element = get_html_object(el);

	if (!element) {
		return false;
	}

	if (element.classList.contains(old_classname)) {
		return element.classList.replace(old_classname, new_classname);
	}
}

export function observe_el(target, options, callback, exit_callback = null, unobserve = false) {
	const el_target = document.querySelector(target);
	// const observer_options = options;

	if (el_target === null || typeof el_target === "undefined") {
		return;
	}

	function handle_intersection(entries, observer) {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				// Callback here
				callback(entry);

				// Unobserve the element
				if (unobserve) {
					observer.unobserve(entry.target);
				}
			} else {
				if (exit_callback) {
					exit_callback();
				}
			}
		});
	}

	const observer = new IntersectionObserver(handle_intersection, options);
	observer.observe(el_target);

	return;
}
