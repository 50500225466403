import "../css/input.css";
import {
	add_class_if_not_exists,
	remove_class_if_exists,
	replace_class,
	observe_el,
} from "./helpers";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import CSSRulePlugin from "gsap/CSSRulePlugin";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import "lazysizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import marquee from "vanilla-marquee";
import Swiper, { Keyboard, Navigation, Pagination, A11y, Autoplay } from "swiper";
import "swiper/css";

gsap.registerPlugin(ScrollTrigger, CSSRulePlugin, ScrollToPlugin);

// Digit counter
gsap.registerEffect({
	name: "counter",
	extendTimeline: true,
	defaults: {
		end: 0,
		duration: 0.5,
		ease: "power1",
		increment: 1,
	},
	effect: (targets, config) => {
		let tl = gsap.timeline();
		let num = targets[0].innerText.replace(/\,/g, "");
		targets[0].innerText = num;

		tl.to(
			targets,
			{
				duration: config.duration,
				innerText: config.end,
				//snap:{innerText:config.increment},
				modifiers: {
					innerText: function (innerText) {
						return gsap.utils
							.snap(config.increment, innerText)
							.toString()
							.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					},
				},
				ease: config.ease,
			},
			0
		);

		return tl;
	},
});

function animate_navbar() {
	const navbar_wrapper = document.getElementById("main-navigation-wrapper");
	const navbar_btn = document.getElementById("nav-cta-btn");
	const nav_logo = document.querySelector(".nav-logo").querySelector("svg");

	if (navbar_wrapper === null) {
		return;
	}

	let window_post = window.scrollY;
	set_navbar_state(window_post, navbar_wrapper, navbar_btn, nav_logo);

	document.addEventListener("scroll", (e) => {
		window_post = window.scrollY;

		set_navbar_state(window_post, navbar_wrapper, navbar_btn, nav_logo);
	});
}
animate_navbar();

function set_navbar_state(y_scroll, navbar, navbar_btn, nav_logo) {
	if (y_scroll > 1) {
		add_class_if_not_exists(navbar, "downwards");
		if (!navbar_btn.classList.contains("btn-primary")) {
			replace_class(navbar_btn, "btn-secondary", "btn-primary");
		}
		nav_logo.setAttribute("fill", "#fff");
	} else {
		remove_class_if_exists(navbar, "downwards");

		if (!navbar_btn.classList.contains("btn-secondary")) {
			replace_class(navbar_btn, "btn-primary", "btn-secondary");
		}

		nav_logo.setAttribute("fill", "#1c1c1c");
	}

	return;
}

/*
	Animate model features
*/
function animate_model_features() {
	let navbar_height = document.querySelector("#main-navigation-wrapper").offsetHeight
		? document.getElementById("main-navigation-wrapper").offsetHeight + 40
		: 75;
	let panels = gsap.utils.toArray(".model-feature");

	panels.forEach((element, index) => {
		let realIndex = index + 1;

		if (index === 0) {
			// Image 1
			ScrollTrigger.create({
				trigger: element,
				pin: true,
				markers: false,
				start: "top top+=" + navbar_height,
				scrub: 0.2,
				pinSpacing: false,
				// end: "-=60%",
				// onEnter: ({ progress, direction, isActive }) =>
				// 	console.log(progress, direction, isActive),
				onEnterBack: ({ progress, direction, isActive }) =>
					gsap.to(element, { opacity: 1, duration: 0.5 }),
				onLeave: ({ progress, direction, isActive }) =>
					gsap.to(element, { opacity: 0, duration: 0.5 }),
				onLeaveBack: ({ progress, direction, isActive }) =>
					gsap.to(element, { opacity: 1, duration: 0.5 }),
			});
		} else {
			ScrollTrigger.create({
				trigger: element,
				pin: true,
				markers: false,
				start: "top top+=" + navbar_height,
				// end: "-=60%",
				scrub: 0.2,
				pinSpacing: false,
				onEnter: ({ progress, direction, isActive }) =>
					gsap.to(element, { opacity: 1, duration: 0.5 }),
				onEnterBack: ({ progress, direction, isActive }) =>
					gsap.to(element, { opacity: 1, duration: 0.5 }),
				onLeave: ({ progress, direction, isActive }) =>
					gsap.to(element, { opacity: 0, duration: 0.5 }),
				onLeaveBack: ({ progress, direction, isActive }) =>
					gsap.to(element, { opacity: 0, duration: 0.5 }),
			});
		}
	});
}

function animate_process_section() {
	const navbar_el = document.getElementById("main-navigation-wrapper");
	const navbar_height = navbar_el.offsetHeight ? navbar_el.offsetHeight + 40 : 75;
	const header_el = document.getElementById("steps-section-header");
	const header_height = header_el ? header_el.offsetHeight + 40 : 150;

	// Main header
	ScrollTrigger.create({
		trigger: "#steps-section-header",
		endTrigger: "#main-end-trigger",
		pin: true,
		markers: false,
		scrub: 0.5,
		start: "top top+=" + navbar_height,
		end: "bottom center",
		pinSpacing: false,
	});

	// Step 1
	ScrollTrigger.create({
		trigger: "#steps-item-1",
		pin: true,
		scrub: 0.5,
		pinnedContainer: ".steps-section > .container",
		// markers: true,
		start: "top top+=" + (navbar_height + header_height),
		end: "bottom top+=" + (navbar_height + header_height),
		pinSpacing: true,
		onLeave: ({ progress, direction, isActive }) =>
			gsap.to("#steps-item-1", { opacity: 0, duration: 0.3 }),
		onEnterBack: ({ progress, direction, isActive }) =>
			gsap.to("#steps-item-1", { opacity: 1, duration: 0.3 }),
	});

	// Step 2
	ScrollTrigger.create({
		trigger: "#steps-item-2",
		pin: true,
		scrub: 0.5,
		pinnedContainer: ".steps-section > .container",
		// markers: true,
		start: "top top+=" + (navbar_height + header_height),
		end: "bottom top+=" + (navbar_height + header_height),
		pinSpacing: false,
		onEnter: ({ progress, direction, isActive }) =>
			gsap.to("#steps-item-2", { opacity: 1, duration: 0.3 }),
		onLeave: ({ progress, direction, isActive }) =>
			gsap.to("#steps-item-2", { opacity: 0, duration: 0.3 }),
		onEnterBack: ({ progress, direction, isActive }) =>
			gsap.to("#steps-item-2", { opacity: 1, duration: 0.3 }),
		onLeaveBack: ({ progress, direction, isActive }) =>
			gsap.to("#steps-item-2", { opacity: 0, duration: 0.3 }),
	});

	// Step 3
	ScrollTrigger.create({
		trigger: "#steps-item-3",
		pin: true,
		scrub: 0.5,
		pinnedContainer: ".steps-section > .container",
		endTrigger: "#main-end-trigger",
		markers: false,
		start: "top top+=" + (navbar_height + header_height),
		end: "clamp(bottom 70%)",
		pinSpacing: true,
		onEnter: ({ progress, direction, isActive }) =>
			gsap.to("#steps-item-3", { opacity: 1, duration: 0.3 }),
		onLeave: ({ progress, direction, isActive }) =>
			gsap.to("#steps-item-3", { opacity: 0, duration: 0.3 }),
		onEnterBack: ({ progress, direction, isActive }) =>
			gsap.to("#steps-item-3", { opacity: 1, duration: 0.3 }),
		onLeaveBack: ({ progress, direction, isActive }) =>
			gsap.to("#steps-item-3", { opacity: 0, duration: 0.3 }),
	});
}

/*
	Animate model images
*/
function animate_model_img() {
	gsap.to(".img-scale-anim", {
		scale: 1,
		duration: 0.8,
		ease: "power3.out",
		stagger: 0.1,
	});
}

function set_animation_defaults() {
	gsap.set(
		".social-proof, .function-section, .function-section .item-function, #model-options-header, #models-comparison-section, .models-pref-list .model-type, #launch-kickstarter, #product-benefits-header, .free-tumbler-section",
		{
			opacity: 0,
			y: 50,
		}
	);

	gsap.set(".img-scale-anim", { scale: 1.2 });

	// Hide first model feature image
	gsap.set("#model-feature-1, .product-benefit", { opacity: 0 });

	// Set model feature temps to zero
	document.querySelectorAll("#model-option-35, #model-option-20").forEach((temp) => {
		temp.innerHTML = "0";
	});

	// Remove hidden class from model features
	document
		.querySelectorAll(
			"#model-feature-2, #model-feature-3, #model-feature-4, #model-feature-5, #model-feature-6"
		)
		.forEach((img) => {
			if (img.classList.contains("hidden")) {
				img.classList.remove("hidden");
			}
		});

	// Kickstarter product
	add_class_if_not_exists("#product-design-launch", "border-opacity-0");

	const kickstarter_elements = document.querySelectorAll("#kickstarter-txt, #kickstarter-logo");
	kickstarter_elements.forEach((element) => {
		add_class_if_not_exists(element, "opacity-0");
	});
}

function animate_menu_scroll() {
	const navbar_el = document.getElementById("main-navigation-wrapper");
	const navbar_height = navbar_el.offsetHeight ? navbar_el.offsetHeight + 40 : 75;

	document.querySelectorAll("#main-nav a").forEach((item, index) => {
		item.addEventListener("click", (event) => {
			// Prevent the default action
			event.preventDefault();
			let target = event.target;
			let hash = target.hash;

			gsap.to(window, {
				duration: 1,
				scrollTo: { y: hash, offsetY: navbar_height },
				ease: "Power1.easeInOut",
			});
		});
	});
}

function animate_tumbler_discount_section() {
	const navbar_el = document.getElementById("main-navigation-wrapper");
	const navbar_height = navbar_el.offsetHeight ? navbar_el.offsetHeight + 40 : 75;

	ScrollTrigger.create({
		trigger: ".tumbler-discount-section",
		pin: false,
		scrub: 0.5,
		markers: false,
		start: "clamp(top 80%)",
		end: "bottom top+=" + navbar_height,
		pinSpacing: false,
		onUpdate: ({ progress, direction, isActive }) => {
			let multipler = progress * 0.25 + 1;
			gsap.to(".tumbler-discount-section-bg", {
				scale: multipler,
				duration: 2,
			});
		},
	});
}

function set_marquee_animation() {
	const brands_marquee = document.getElementById("brands-list");
	if (brands_marquee) {
		new marquee(document.getElementById("brands-list"), {
			speed: 50,
			duplicated: true,
			gap: window.innerWidth >= 768 ? 75 : 50,
			delayBeforeStart: 0,
			startVisible: true,
			recalcResize: true,
		});
	}
}

function animate_brands_section() {
	observe_el(
		".social-proof",
		{ threshold: 0.7 },
		() => {
			gsap.to(".social-proof", {
				scrollTrigger: ".social-proof",
				y: 0,
				opacity: 1,
				duration: 1.5,
				ease: "expo.out",
			});
		},
		null,
		true
	);
}

function animate_feature_section() {
	observe_el(
		".function-section",
		{ threshold: 0.2 },
		() => {
			gsap.to(".function-section", {
				scrollTrigger: ".function-section",
				y: 0,
				opacity: 1,
				duration: 1.5,
				ease: "expo.out",
			});
		},
		null,
		true
	);
}

function animate_feature_section_list() {
	observe_el(".function-section .item-function-list", { threshold: 0.2 }, () => {
		gsap.to(".function-section .item-function", {
			y: 0,
			opacity: 1,
			duration: 1.5,
			ease: "expo.out",
			stagger: 0.4,
		});
	});
}

function animate_model_images() {
	observe_el(".img-scale-anim", { threshold: 0.5 }, animate_model_img, null, true);
}

function animate_model_35_img() {
	observe_el(
		"#model-option-35",
		{ threshold: 0.2 },
		() => {
			let tl = gsap.timeline();
			tl.counter("#model-option-35", { end: 35, duration: 2, ease: "power3.out" }, 0);
		},
		null,
		true
	);
}

function animate_model_20_img() {
	observe_el(
		"#model-option-20",
		{ threshold: 0.2 },
		() => {
			let tl = gsap.timeline();
			tl.counter("#model-option-20", { end: 20, duration: 2, ease: "power3.out" }, 0);
		},
		null,
		true
	);
}

function animate_initial_model_img() {
	observe_el(
		"#model-feature-1",
		{ threshold: 0.2 },
		() => {
			gsap.to("#model-feature-1", {
				opacity: 1,
				duration: 0.8,
				ease: "power4.in",
			});
		},
		null,
		true
	);
}

function animate_options_section_header() {
	observe_el("#model-options-header", { threshold: 0.2 }, () => {
		gsap.to("#model-options-header", {
			y: 0,
			opacity: 1,
			duration: 1.5,
			ease: "expo.out",
		});
	});
}

function animate_options_section() {
	observe_el(
		"#models-comparison-section",
		{ threshold: 0.2 },
		() => {
			gsap.to("#models-comparison-section", {
				y: 0,
				opacity: 1,
				duration: 1.5,
				ease: "expo.out",
			});
		},
		null,
		true
	);
}

function animate_options_section_list() {
	observe_el(
		".models-pref-list",
		{ threshold: 0.2 },
		() => {
			gsap.to(".models-pref-list .model-type", {
				y: 0,
				opacity: 1,
				duration: 1.5,
				stagger: 0.4,
				ease: "expo.out",
			});
		},
		null,
		true
	);
}

function animate_kickstarter_section() {
	observe_el("#launch-kickstarter", { threshold: 0.2 }, () => {
		gsap.to("#launch-kickstarter", {
			y: 0,
			opacity: 1,
			duration: 1.5,
			ease: "expo.out",
		});
	});
}

function animate_kickstarter_launch_design() {
	observe_el("#product-design-launch", { threshold: 0.8 }, () => {
		gsap.to("#kickstarter-logo", {
			opacity: 1,
			duration: 0.5,
			ease: "expo.1",
		});
		replace_class("#product-design-launch", "border-opacity-0", "border-opacity-100");
		gsap.to("#kickstarter-txt", {
			opacity: 1,
			duration: 0.5,
			ease: "expo.1",
		});
	});
}

function animate_product_benefits_header() {
	observe_el("#product-benefits-header", { threshold: 0.2 }, () => {
		gsap.to("#product-benefits-header", {
			y: 0,
			opacity: 1,
			duration: 1.5,
			ease: "expo.out",
		});
	});
}

function animate_product_benefits_list() {
	observe_el("#product-benefits-header", { threshold: 0.2 }, () => {
		gsap.to(".product-benefit", {
			opacity: 1,
			duration: 0.3,
			stagger: 0.2,
			ease: "expo.in",
		});
	});
}

function animate_tumbler_section() {
	observe_el(".free-tumbler-section", { threshold: 0.2 }, () => {
		gsap.to(".free-tumbler-section", {
			y: 0,
			opacity: 1,
			duration: 1.5,
			ease: "expo.out",
		});
	});
}

/*
	DOCUMENT READY
*/
window.addEventListener("DOMContentLoaded", () => {
	// Marquee to brand section
	set_marquee_animation();

	// Set intial state
	set_animation_defaults();

	// Animate social proof
	animate_brands_section();

	// Animate "perfect tumbler section"
	animate_feature_section();

	// Animate "perfect tumbler section list items"
	animate_feature_section_list();

	// Menu scrolling functionality
	animate_menu_scroll();

	animate_model_features();

	animate_process_section();

	// Animate model images
	animate_model_images();

	// animate model temps - 35
	animate_model_35_img();

	// animate model temps - 20
	animate_model_20_img();

	// Model feature - show first image
	animate_initial_model_img();

	animate_tumbler_discount_section();

	// Model options header
	animate_options_section_header();

	// "Which comfortsip... section"
	animate_options_section();

	// "Which comfortsip... section" - list items
	animate_options_section_list();

	// Hot & colde beverage.. section
	animate_kickstarter_section();

	// Kickstarter product
	animate_kickstarter_launch_design();

	// Product benefits header
	animate_product_benefits_header();

	// Product benefits list
	animate_product_benefits_list();

	// Tumbler section
	animate_tumbler_section();

	// Discount dialog event listener
	setTimeout(() => {
		window.addEventListener("scroll", window_scroll_dialog);
	}, 1000);
});

function window_scroll_dialog(e) {
	let document_height = document.body.offsetHeight;
	let scroll_position = Math.floor(window.scrollY);
	let scroll_percentage = Math.floor((scroll_position / document_height) * 100);

	// Open dialog when screem is at 5% of the page
	if (scroll_percentage >= 5) {
		open_discount_dialog();
	}
}

function hide_landamrks() {
	const other_landmarks = document.querySelectorAll(
		"main, #main-navigation-wrapper, header, footer"
	);
	other_landmarks.forEach((landmark) => {
		landmark.setAttribute("aria-hidden", "true");
		landmark.setAttribute("tabindex", "-1");
	});
}

function show_landamrks() {
	const other_landmarks = document.querySelectorAll(
		"main, #main-navigation-wrapper, header, footer"
	);
	other_landmarks.forEach((landmark) => {
		landmark.setAttribute("aria-hidden", "false");
		landmark.removeAttribute("tabindex");
	});
}

function open_discount_dialog() {
	// Check if dialog has been opened before
	const dialog_opened = Modernizr.localstorage
		? localStorage.getItem("discount_dialog_opened")
		: null;

	if (document.body.classList.contains("dialog-opened") || dialog_opened) {
		return;
	}

	const dialog = document.querySelector("#discount-dialog-backdrop");
	if (!dialog) {
		return;
	}

	// Set local storage
	if (Modernizr.localstorage) {
		localStorage.setItem("discount_dialog_opened", "true");
	}

	// Open dialog
	hide_landamrks();
	dialog.setAttribute("aria-hidden", "false");
	replace_class(dialog, "hidden", "block");
	gsap.to(dialog, {
		opacity: 1,
		duration: 1,
		ease: "expo.inOut",
	});
	add_class_if_not_exists(document.body, "dialog-opened");

	// Add event listeners to buttons
	const close_btns = document.querySelectorAll(
		"#discount-dialog-backdrop #dialog_close, #discount-dialog-backdrop #dialog_link"
	);
	close_btns.forEach((btn) => {
		btn.addEventListener("click", () => {
			close_discount_dialog();
		});
	});

	// Detect escape key
	document.addEventListener("keydown", (e) => {
		if (e.key === "Escape") {
			close_discount_dialog();
		}
	});
}

function close_discount_dialog() {
	const dialog = document.querySelector("#discount-dialog-backdrop");

	// Close dialog
	show_landamrks();
	dialog.setAttribute("aria-hidden", "true");
	gsap.to(dialog, {
		opacity: 0,
		duration: 0.5,
		ease: "power4.out",
		onComplete: () => {
			replace_class(dialog, "block", "hidden");
		},
	});
	remove_class_if_exists(document.body, "dialog-opened");

	// Remove scroll event listener
	window.removeEventListener("scroll", window_scroll_dialog);
}

function init_slideshow() {
	const swiper = new Swiper(".swiper", {
		modules: [Keyboard, Navigation, Pagination, A11y, Autoplay],
		direction: "horizontal",
		speed: 800,
		effect: "slide",
		rewind: true,
		spaceBetween: 40, //px
		grabCursor: true,
		initialSlide: 1,
		preloadImages: false,
		ally: true,
		autoplay: {
			delay: 4700,
			disableOnInteraction: false,
		},
		pagination: {
			el: ".slideshow-pagination",
			type: "bullets",
			clickable: true,
			modifierClass: "pagination-bullet-",
			clickableClass: "pagination-bullet-clickable",
			hiddenClass: "pagination-bullet-hidden",
			bulletClass: "pagination-bullet",
			bulletActiveClass: "pagination-bullet-active",
			currentClass: "pagination-bullet-current",
			lockClass: "pagination-bullet-lock",
			bulletElement: "div",
			renderBullet: function (index, className) {
				return '<div id="pagination-bullet-' + index + '" class="' + className + '"></div>';
			},
		},
		navigation: {
			prevEl: ".slide-btn-prev",
			nextEl: ".slide-btn-next",
			disabledClass: "slide-button-disabled",
			hiddenClass: "slide-button-hidden",
			lockClass: "slide-button-lock",
			navigationDisabledClass: "slide-nav-disabled",
		},
		keyboard: {
			enabled: true,
			onlyInViewport: true,
		},
	});
}

// Init slideshow
init_slideshow();

document.addEventListener("lazyloaded", function (e) {
	let el = e.target;
	let el_parent;
	let el_ancestor;
	let prev_sibling;

	if (!el.classList.contains("header-section")) {
		el_parent = e.target.parentNode;
		el_ancestor = el_parent.parentNode;
		prev_sibling = el_parent.previousElementSibling;

		if (prev_sibling !== null && prev_sibling.classList.contains("lazy-loader")) {
			prev_sibling.remove();
		} else {
			prev_sibling = el_ancestor.querySelector(".lazy-loader");

			if (prev_sibling !== null) {
				prev_sibling.remove();
			}
		}
	}

	return;
});
